
















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Team from '@improve/common-utils/src/model/Team';
import Ticket from '@improve/common-utils/src/model/Ticket';
import BaseStatsPills from '@improve/common-components/src/components/widgets/BaseStatsPills.vue';
import BaseTicketSkeletonCard
  from '@improve/common-components/src/components/widgets/BaseTicketSkeletonCard.vue';
import TicketSearchParams from '@improve/common-utils/src/types/TicketSearchParams';
import StatsData from '@improve/common-utils/src/model/StatsData';
import BaseAddButton from '@improve/common-components/src/components/widgets/BaseAddButton.vue';
import BaseTicketCard from '../ticket/BaseTicketCard.vue';

@Component({
  name: 'TeamImprovesTab',
  components: {
    BaseStatsPills,
    BaseAddButton,
    BaseTicketSkeletonCard,
    BaseTicketCard
  }
})
export default class TeamImprovesTab extends Vue {
  @Prop({ default: null }) readonly team!: Team;

  @Getter activeWorkflowStatuses!: Array<string>;

  @Getter teamStatsByID!: Map<string, StatsData>;

  @Action getAllTickets!: ActionMethod;

  @Action fetchTransitions!: ActionMethod;

  ticketFetchInProgress = true;

  tickets: Array<Ticket> = [];

  get showImprovesSection(): boolean {
    return this.ticketFetchInProgress || !!this.tickets.length;
  }

  get ticketCountTotal(): number {
    const stats = this.teamStatsByID.get(this.team.id!);
    return stats?.ticketStats?.totalNonArchived || 0;
  }

  async created(): Promise<void> {
    await this.fetchTransitions();
    await this.fetchTeamTickets();
  }

  async fetchTeamTickets(): Promise<void> {
    const ticketFilter = new TicketSearchParams();
    ticketFilter.delegate = [this.team.getDelegateId()];
    ticketFilter.status = this.activeWorkflowStatuses;
    this.tickets = await this.getAllTickets(ticketFilter);
    this.ticketFetchInProgress = false;
  }

  ticketCountPerStatus(status: string): number | undefined {
    const stats = this.teamStatsByID.get(this.team.id!);
    return stats?.ticketStats?.byStatus.get(status);
  }

  goToCreateImprove(): void {
    this.$router.push({
      name: 'CreateTicket',
      params: { teamId: this.team.id! }
    });
  }
}
